<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-12 col-lg">
						<label class="control-label my-2 ml-2">
							<!-- <h6>Jumlah Data : {{ recordsFiltered }}</h6> -->
						</label>
					</div>
					<div class="col-auto">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-default" v-on:click="showTable">
								<i class="fa fa-sync-alt"></i> Show Table
							</button>
							<button
								type="button"
								:disabled="downloading"
								v-on:click="downloadXlsx"
								data-action="export-xlsx"
								class="btn btn-default"
							>
								<i class="fa fa-download"></i> Download
							</button>
						</div>
					</div>
				</div>
				<div class="table-responsive" :hidden="hideTable">
				<table class="table table-hover" ref="tableanomali" id="tableanomali">
					<thead>
						<tr>
							<th>ID</th>
							<th>Nama</th>
							<th>Jurnal</th>
							<th>BosPay</th>
							<th>Selisih</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
	name: "Shipper",
	data() {
		return {
			roles: "",
			userCS: [],
			filter: {},
			dt1: moment().startOf("month"),
			dt2: moment(),
			data_type: "nama",
			recordsFiltered: 0,
			disabled: false,
			hideTable: true
		};
	},
	computed: {},
	components: {},
	watch:{},
	created: function () {},
	methods: {
		handleClick(e) {},
		showTable: function () {
			if (this.tableAnomali) {
				this.tableAnomali.api().ajax.reload();
			} else {
				this.hideTable = false;
				const e = this.$refs;
				var self = this;
				this.tableAnomali = createTable(e.tableanomali, {
					title: "",
					roles: this.$route.params.roles,
					ajax: "/shipper/anomali",
					frame: true,
					scrollX: true,
					processing: true,
					autoWidth:false,
					language: { processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>' },
					paramData: function (d) {
						var filter = JSON.parse(JSON.stringify(self.filter));
						d.dt1 = self.dateStart;
						d.dt2 = self.dateEnd;
						var filter = JSON.parse(JSON.stringify(self.filter));
						for (const key in filter) {
							d[key] = filter[key] === false ? 0 : filter[key];
						}
					},
					columns: [
						{ data: "shipper_code" },
						{ data: "nama" },
						{ data: "total_jr" },
						{ data: "total_dp" },
						{ data: "different" },
					],
					filterBy: [0, 1],
					rowCallback: function (row, data) {
						$("td:eq(2)", row).html("Rp " + formatCurrency(data.total_jr));
						$("td:eq(3)", row).html("Rp " + formatCurrency(data.total_dp));
						$("td:eq(4)", row).html("Rp " + formatCurrency(data.different));
					},
				});
			}
		},
		downloadXlsx: function (e) {
			this.downloading = true;
			var table = $('#tableanomali').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			}

			var filter = JSON.parse(JSON.stringify(this.filter));
                filter.q = this.tableAnomali ? $("input[name=q]").val() : '';
                filter.category = this.tableAnomali ? $("select[name=category]").val() : 0;

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");

			console.log(data)
			this.loadingContent = true;
			authFetch("/report/anomali_bospay/excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					// return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.downloading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "anomali-bospay.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		},
	},
	mounted() {
		$('.loading-overlay').removeClass('show');
	},
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>